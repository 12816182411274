import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
<path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m2513 1415 c88 -26 177 -76 237 -131 53 -50 130 -142 130 -157 0 -3
-24 -14 -53 -23 l-53 -17 -75 75 c-105 105 -206 148 -344 148 -131 -1 -235
-41 -321 -125 -81 -79 -130 -171 -149 -277 l-6 -38 44 0 c101 0 400 49 592 97
175 43 434 122 559 170 110 42 138 38 36 -5 -41 -17 -129 -55 -195 -85 -337
-151 -733 -276 -1010 -317 -148 -22 -145 -24 -145 108 0 142 32 239 119 356
24 33 57 71 73 84 17 14 35 30 42 36 6 6 29 23 51 37 126 80 325 107 468 64z
m766 -298 c-50 -42 -745 -391 -944 -474 -33 -14 -82 -35 -110 -48 -86 -39
-286 -116 -390 -151 -159 -52 -254 -75 -340 -81 -72 -5 -83 -3 -107 17 -34 26
-35 54 -7 115 24 51 33 56 36 19 2 -50 24 -59 136 -58 73 0 127 7 187 23 164
43 359 110 522 180 53 22 99 41 101 41 3 0 26 9 52 21 80 35 42 32 -66 -6
-379 -134 -726 -204 -802 -163 -59 31 -63 90 -11 148 39 43 65 55 40 18 -19
-27 -21 -72 -3 -86 52 -43 354 1 671 99 473 145 654 213 934 349 53 26 99 46
101 44 2 -2 2 -6 0 -7z m-311 -238 c-2 -6 -47 -38 -100 -72 -53 -34 -127 -83
-165 -108 -165 -112 -375 -235 -510 -299 -33 -16 -64 -33 -66 -38 -7 -10 12
-93 24 -106 5 -6 9 -23 9 -38 l0 -28 192 2 192 3 11 45 c22 87 70 176 142 265
41 50 81 111 92 140 15 41 30 58 85 99 37 26 69 46 72 43 10 -9 -26 -137 -56
-201 -17 -36 -60 -100 -96 -143 -71 -85 -96 -131 -119 -219 -20 -78 -43 -114
-90 -138 -36 -18 -57 -20 -230 -20 -228 0 -257 8 -292 83 -12 25 -25 62 -29
81 -8 45 -41 115 -76 161 l-28 36 48 13 c75 20 197 64 382 140 101 41 526 253
570 284 40 29 43 30 38 15z m-380 -891 c20 -20 15 -86 -7 -98 -12 -6 -103 -10
-226 -10 -123 0 -214 4 -226 10 -22 12 -27 78 -7 98 17 17 449 17 466 0z m-51
-193 c20 -28 17 -82 -6 -95 -11 -6 -88 -10 -180 -10 -181 0 -191 4 -191 70 0
36 3 40 32 48 18 4 99 7 182 6 132 -1 151 -3 163 -19z m-372 -175 c0 -34 -1
-35 -42 -38 l-43 -3 0 -210 0 -209 -35 0 -35 0 -2 208 -3 207 -42 3 c-42 3
-43 4 -43 37 0 33 1 34 43 37 23 2 78 3 122 3 l80 0 0 -35z m685 -12 c0 -42
-24 -88 -46 -88 -11 0 -11 6 -3 27 8 22 7 30 -5 39 -19 13 -19 15 -7 47 8 19
15 23 35 20 23 -5 26 -10 26 -45z m-552 -228 c1 -199 0 -211 -19 -221 -11 -5
-28 -7 -39 -4 -17 6 -19 19 -20 220 l-1 214 33 3 c18 2 36 2 39 1 4 -2 7 -97
7 -213z m170 187 c5 -12 22 -75 37 -140 16 -65 32 -120 36 -123 5 -3 11 8 14
23 22 101 68 259 78 265 7 4 29 8 50 8 l37 0 0 -220 0 -220 -40 0 -39 0 -3
133 c-3 148 -6 146 -49 -35 -20 -89 -25 -98 -45 -98 -25 0 -26 3 -65 163 -12
48 -25 87 -30 87 -5 0 -9 -56 -9 -125 l0 -125 -35 0 -35 0 0 214 c0 148 3 215
11 218 28 9 77 -5 87 -25z m628 23 c38 0 74 -45 74 -91 0 -38 -1 -39 -35 -39
-28 0 -35 4 -35 19 0 40 -53 65 -88 43 -18 -12 -25 -78 -9 -89 6 -5 44 -19 83
-31 80 -25 84 -30 84 -120 0 -60 -20 -108 -49 -117 -36 -11 -138 -7 -160 7
-22 13 -35 44 -35 88 0 21 5 24 35 25 30 0 34 -3 37 -26 2 -18 11 -29 25 -33
28 -7 67 3 68 18 1 6 3 25 5 42 4 40 -4 46 -93 74 l-73 22 0 82 c0 101 12 123
73 129 23 2 48 2 55 0 8 -2 25 -3 38 -3z m-1215 -657 c2 -23 0 -45 -4 -49 -4
-4 -7 17 -7 46 0 62 7 64 11 3z m109 -10 l0 -48 -19 24 -19 25 -5 -25 c-2 -14
-5 -5 -5 21 l-2 45 20 -25 20 -25 0 28 c0 15 2 27 5 27 3 0 5 -21 5 -47z m93
5 c-7 -53 -13 -62 -13 -20 0 16 -3 37 -6 46 -4 10 -1 16 9 16 12 0 14 -9 10
-42z m117 33 c0 -5 -10 -12 -22 -16 l-23 -8 23 -4 c12 -2 22 -8 22 -13 0 -4
-10 -11 -22 -15 l-23 -8 23 -4 c12 -2 22 -9 22 -14 0 -6 -10 -9 -22 -7 -18 2
-24 11 -26 36 -2 19 -1 40 2 48 6 15 46 20 46 5z m114 -8 c3 -10 1 -34 -3 -53
-5 -19 -9 -27 -10 -17 -1 18 -21 25 -21 7 0 -5 -4 -10 -10 -10 -11 0 -14 73
-3 83 13 13 42 7 47 -10z m216 7 c0 -5 -7 -10 -15 -10 -21 0 -19 -16 3 -25 15
-6 14 -8 -8 -18 -25 -10 -25 -10 -2 -14 12 -3 22 -9 22 -14 0 -6 -10 -9 -22
-7 -18 2 -24 11 -26 36 -2 19 -1 40 2 48 6 16 46 19 46 4z m110 0 c0 -5 -5
-10 -11 -10 -7 0 -9 -10 -7 -24 3 -14 1 -31 -3 -38 -6 -8 -9 -2 -9 19 0 17 -3
38 -6 47 -4 12 0 16 15 16 12 0 21 -4 21 -10z m164 4 c9 -3 14 -10 11 -15 -3
-4 -12 -6 -20 -2 -8 3 -15 0 -15 -5 0 -6 9 -14 20 -17 24 -8 27 -40 4 -49 -17
-7 -34 0 -34 14 0 5 7 7 15 4 8 -4 15 -1 15 5 0 7 -7 14 -15 17 -15 6 -20 35
-8 47 8 8 8 8 27 1z m116 -4 c0 -5 -7 -10 -15 -10 -8 0 -15 -4 -15 -10 0 -5 7
-10 15 -10 22 0 18 -17 -5 -23 -17 -4 -20 0 -20 29 0 27 4 34 20 34 11 0 20
-4 20 -10z m114 -7 c3 -10 1 -34 -3 -53 -5 -19 -9 -27 -10 -17 -1 18 -21 25
-21 7 0 -5 -4 -10 -10 -10 -11 0 -14 73 -3 83 13 13 42 7 47 -10z m290 1 c4
-11 1 -14 -13 -9 -15 4 -20 0 -23 -25 -4 -29 -3 -31 18 -26 18 5 22 3 17 -9
-3 -9 -15 -15 -27 -13 -23 3 -32 33 -22 74 7 27 40 33 50 8z m106 6 c0 -5 -7
-10 -15 -10 -20 0 -19 -10 3 -26 16 -13 16 -13 0 -14 -26 0 -22 -18 5 -23 19
-3 20 -4 5 -11 -31 -14 -48 1 -48 42 0 21 3 42 7 45 10 11 43 8 43 -3z m-1036
-17 c4 -9 3 -19 -3 -22 -5 -3 -11 -15 -14 -26 -2 -11 -5 -1 -6 23 -1 42 11 56
23 25z m36 -23 c0 -39 -13 -54 -23 -27 -7 18 3 67 14 67 5 0 9 -18 9 -40z
m680 20 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 5 20 11 20 6 0 8 -9 5 -20
-3 -11 -9 -29 -12 -40 -3 -11 -9 -20 -14 -20 -8 0 -11 8 -26 63 -3 9 -1 17 5
17 6 0 11 -9 11 -20z m110 -19 c0 -22 -4 -43 -10 -46 -6 -4 -10 12 -10 39 0
25 5 46 10 46 6 0 10 -18 10 -39z m-300 -41 c0 -5 -9 -10 -20 -10 -11 0 -20 5
-20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z"/>
<path d="M2280 1430 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"/>
<path d="M3000 1430 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
